export default class DmRoutesConstant {
  public static readonly AdvancedSearchRouteName: string = 'advancedsearch';
  public static readonly AdvancedSearchRouteUrl: string = '/supportdelivery/search';
  public static readonly MyDeliveriesRouteName: string = 'mydeliveries';
  public static readonly MyDeliveriesRouteUrl: string = '/supportdelivery/mydeliveries?status=';
  public static readonly RequestDetailsRouteName: string = 'supportdelivery_requestdetails';
  public static readonly RequestDetailsRouteUrl: string = '/supportdelivery/requestdetails/{requestId}';
  public static readonly RequestNotFoundName: string = 'notfound';
  public static readonly RequestNotFoundUrl: string = '/supportdelivery/notfound';
  public static readonly RequestSubmisionRouteUrl: string = '/supportdelivery/submitrequest';
  public static readonly RequestSubmissionRouteName: string = 'submitrequest';
  public static readonly ResourcingInfoRouteName: string = 'resourcing_info';
  public static readonly ResourcingInfoRouteUrl: string = 'supportdelivery/resourcinginfo';
  public static readonly SubmissionRouteName: string = 'submission';
  public static readonly SubmissionRouteUrl: string = '/supportdelivery/submission';
  public static readonly WorkshopRouteName: string = 'workshops';
  public static readonly WorkshopsRouteUrl: string = '/supportdelivery/workshops';
  public static readonly WorkshopManageAttendeesRouteName: string = 'manageattendees';
  public static readonly WorkshopManageAttendeesUrl: string =
    '/supportdelivery/workshops/manageattendees/{trackingNumber}';
  public static readonly SupportNeedsRouteName: string = 'supportneeds';
  public static readonly SupportNeedsRouteUrl: string = '/supportdelivery/supportneeds';
  public static readonly RequestUnauthorizedName: string = 'unauthorize';
  public static readonly RequestUnauthorizedUrl: string = '/supportdelivery/unauthorized?type=';
  public static readonly EmailTemplatesRouteName: string = 'emailtemplates';
  public static readonly EmailTemplatesRouteUrl: string = '/supportdelivery/emailtemplates';
  public static readonly templatesRouteUrl: string = '/supportdelivery/templates';
  public static readonly templatesRouteName: string = 'templates';
  public static readonly languageRouteUrl: string = '/supportdelivery/languages';
  public static readonly languageRouteName: string = 'languages';
  public static readonly settingsRouteUrl: string = '/supportdelivery/settings';
  public static readonly settingsRouteName: string = 'settings';
  public static readonly DSESTARequestMigrationRouteName: string = 'dsestarequestmigration';
  public static readonly DSESTARequestMigrationRouteUrl: string = '/supportdelivery/dsestarequest/migration';
  public static readonly ReportsRouteName: string = 'reports';
  public static readonly ReportsRouteUrl: string = '/supportdelivery/reports';
  public static readonly SeatingReportRouteName: string = 'seatingreport';
  public static readonly SeatingReportRouteUrl: string = '/supportdelivery/reports/seatingreport';
  public static readonly WorkshopReportRouteName: string = 'workshopreport';
  public static readonly WorkshopReportRouteUrl: string = '/supportdelivery/reports/workshopreport';
  public static readonly SupportNeedDetailsRouteName: string = 'supportneedsDetails';
  public static readonly SupportNeedDetailsRouteUrl: string = '/supportdelivery/supportneeds/{requestId}';
  public static readonly OnCallSearchRouteName: string = 'oncallsearch';
  public static readonly OnCallSearchRouteUrl: string = '/supportdelivery/oncallsearch';
  public static readonly SharedComponentSandboxRouteName: string = 'sharedcomponentsandbox';
  public static readonly SharedComponentSandboxRouteUrl: string = '/supportdelivery/sharedcomponentsandbox';
}
