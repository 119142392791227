import { Environments } from './environments';
import { dev } from '../configs/appSettings.dev';
import { prod } from '../configs/appSettings.prod';
import { sit } from '../configs/appSettings.sit';
import { uat } from '../configs/appSettings.uat';
import { settings } from '../configs/appSettings';
import { auroraSit } from '../configs/appSettings.aurorasit';
import { UtilitiesHelperService } from '../../app/helpers/utilities-helper-service';

export const appSettings = (): any => {
  let environmentAppSetting;
  switch (window['FxpAppSettings'].EnvironmentName) {
    case Environments.Dev: environmentAppSetting = dev; break;
    case Environments.Sit: environmentAppSetting = sit; break;
    case Environments.Uat: environmentAppSetting = uat; break;
    case Environments.Prod: environmentAppSetting = prod; break;
    case Environments.AuroraSit: environmentAppSetting = auroraSit; break;
    default: environmentAppSetting = dev; break;
  }

  const mergedSettings = UtilitiesHelperService.mergeObjects(settings, environmentAppSetting);

  return mergedSettings;
};
