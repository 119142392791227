import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const settings: IPartnerAppSettings = {
  cdnBaseUrl: 'http://localhost:5003/',
  serviceEndPoints: [
    {
      clientId: 'c8955af6-b4a7-4e96-86b7-ad0930c25fa7',
      serviceEndPoint: 'https://dmsitafd.azurefd.net/'
    }
  ],
  DmAppConfiguration: {
    laborEntryUrl: 'time/timeentry',
    dmServiceApi: {
      acceptAssignRequest: 'api/v1/Maintenance/AcceptAssignRequest/%s',
      assignServiceCenterRequest: 'api/v1/Maintenance/ServiceCenterSpecialist/%s',
      addClosedWorkshopAttendee: 'api/v1/Attendee/ClosedWorkshop/AddAttendee',
      approveForecast: 'api/v1/Forecast/Approve/%s',
      assignForecast: 'api/v1/Forecast/Assign/%s/%s',
      assignResource: 'api/v1/Maintenance/AssignResource',
      cancelAttendee: 'api/v1/Attendee/RegistrationStatus/%s',
      cancelRequest: 'api/v1/Maintenance/Request/Cancel',
      cancelResourcelessRequest: 'api/v1/Requests/%s/Cancel',
      changeAttendeeStatus: 'api/v1/Attendee/AttendanceStatus/%s/%s',
      completeLaborAssist: 'api/v1/Maintenance/Assignment/%s/LaborAssist',
      confirmWaitlistAttendee: 'api/v1/Attendee/ConfirmWaitlistAttendee/%s',
      createCapacity: 'api/v1/Workshop/CreateCapacity',
      createDraftDelivery: 'api/v1/Draft',
      createEmailTemplate: 'api/v1/Template',
      createForecast: 'api/v1/Forecast',
      createLocation: 'api/v1/Workshop/createLocation',
      createOpportunity: 'api/v1/Opportunity/Create',
      createPlannedItem: 'api/v1/ScopingPlan/PlannedItem',
      createScoping: 'api/v1/Scoping/Create',
      declineForecast: 'api/v1/Forecast/Decline/%s',
      deleteAttachment: 'api/v1/Attachments/%s',
      deleteCapacity: 'api/v1/Workshop/DeleteCapacity/%s',
      deleteClosedWorkshopAttendee: 'api/v1/Attendee/ClosedWorkshop/DeleteAttendee/%s',
      deleteEmailTemplate: 'api/v1/Template/%s',
      deleteLocation: 'api/v1/Workshop/deleteLocation/%s',
      deleteOpportunity: 'api/v1/Opportunity/%s',
      deletePlannedItem: 'api/v1/ScopingPlan/%s/PlannedItem',
      disqualifySupportNeeds: 'api/v1/Opportunity/Disqualify/%s',
      deleteWorkshopDocument: 'api/v1/WorkshopDocument/Delete/%s',
      downloadWorkshopDocument: 'api/v1/WorkshopDocument/%s/Download',
      duplicateDelivery: 'api/v1/Maintenance/DuplicateDelivery',
      emailScopingPlan: 'api/v1/ScopingPlan/Email/%s',
      exportAllWorkshops: 'api/v1/Workshop/%s/ExportAllWorkshops',
      exportAssignments: 'api/v1/Landing/Assignments/ExcelExport',
      exportCapacities: 'api/v1/Workshop/ExportCapacities',
      exportClosedWorkshopAttendees: 'api/v1/Attendee/ClosedWorkshop/ExportAllAttendees/%s',
      exportForecasts: 'api/v1/Forecast/Export/%s',
      exportLocations: 'api/v1/Workshop/exportLocations',
      exportManagedAttendees: 'api/v1/Attendee/Export/%s',
      exportManagedAttendeesAuditLog: 'api/v1/Attendee/ExportAuditLog/%s',
      exportMyWorkshops: 'api/v1/Workshop/ExportMyWorkshops',
      exportPlannedItems: 'api/v1/ScopingPlan/Export',
      exportReporteeAssignments: 'api/v1/Landing/Assignments/Reportee/%s/ExcelExport',
      exportReporteeRequests: 'api/v1/Landing/Requests/Reportee/%s/ExcelExport',
      exportRequests: 'api/v1/Landing/Requests/ExcelExport',
      exportWorkshopDocuments: 'api/v1/WorkshopDocument/Export',
      getActiveWorkshops: 'api/v1/Workshop/ActiveWorkshops',
      getAgreementByAgreementId: 'api/v1/Agreements/agreement/%s?regionId=%s',
      getAgreementDetailsByAgreementId: 'api/v1/Agreements/packages/%s?regionId=%s&tamAlias=%s',
      getAgreementDetailsById: 'api/v1/Requests/%s/AgreementDetails',
      getAgreementEligibilityDetails: 'api/v1/Offerings/GetAgreementEligibilityDetails',
      getAgreementsByTamAlias: 'api/v1/Agreements/%s?agreementNumber=%s',
      getAgreementType: 'api/v1/Agreements/packages/services/%s?pesServiceId=%s',
      getAirports: 'api/v1/Travel/airports',
      getAllAttendees: 'api/v1/Attendee/ClosedWorkshop/%s/GetAllAttendees',
      getAllRequestOfferings: 'api/v1/Search/RequestOfferings',
      getAllRequestProductComponents: 'api/v1/Search/RequestProductComponents',
      getAllWorkshopCounts: 'api/v1/Workshop/%s/AllWorkshopCounts',
      getAllWorkshopDetails: 'api/v1/Workshop/%s/%s/AllWorkshops',
      getAllWorkshopDetailsWithTop: 'api/v1/Workshop/%s/%s/AllWorkshops/%s',
      getAreaGeoInfo: 'api/v1/Geo/geos',
      getAssignmentByAssignmentId: 'api/v1/Assignment/%s',
      getAssignments: 'api/v1/Landing/Assignments?assignmentStatus=%s&landingPageType=%s',
      getAttachmentById: 'api/v1/Attachments/%s/%s',
      getAttachments: 'api/v1/Requests/%s/Attachments',
      getAttendeeCapacity: 'api/v1/Attendee/Capacities/%s',
      getAuditLogById: 'api/v1/Requests/%s/AuditLog',
      getBillingDetailsById: 'api/v1/Requests/%s/%s/BillingDetails',
      getCancellationReasons: 'api/v1/Attendee/CancellationReasons',
      getCapacities: 'api/v1/Workshop/Capacities',
      getCaseDetailsById: 'api/v1/Requests/%s/CaseDetails',
      getContactsById: 'api/v1/Requests/%s/Contacts',
      getNonSupportDraftRequestList: 'api/v1/Draft/MultipleByQueues',
      getCustomerDetailsById: 'api/v1/Requests/%s/CustomerDetails',
      getCustomers: 'api/v1/Opportunity/Customers/%s',
      getDeliverySettings: 'api/v1/DeliverySettings/%s',
      getDocumentLanguages: 'api/v1/WorkshopDocument/DocumentLanguages',
      getDraftDeliveryById: 'api/v1/Draft/%s',
      getEmailLanguages: 'api/v1/Attendee/EmailLanguages',
      getEmailTemplate: 'api/v1/Template/%s/%s',
      getEmailTemplates: 'api/v1/Template',
      getForecastAttendees: 'api/v1/ForecastAttendee/%s',
      getForecastCounts: 'api/v1/Forecast/ForecastCounts',
      getForecasts: 'api/v1/Forecast/%s',
      getGrmAssignmentStatus: 'api/v1/Assignment/GrmStatus/%s',
      getInvitedAttendees: 'api/v1/Attendee/Invites/%s/%s',
      getIssueDetailsById: 'api/v1/Requests/%s/IssueDetails',
      getLaborByRequestId: 'api/v1/Labor/%s',
      getMaintenanceStatus: 'api/v1/Requests/%s/MaintenanceStatus',
      getManagedAttendees: 'api/v1/Attendee/%s',
      getMigratedRequestDetails: 'api/v1/Migration/MigratedRequest/%s',
      getMyWorkshopDetails: 'api/v1/Workshop/%s/%s/MyWorkshops',
      getNonBillableReasons: 'api/v1/Requests/NonBillableReasons',
      getOffering: 'api/v1/Offerings/%s/%s',
      getAllServices: 'api/v1/Offerings/AllServices',
      getOpportunity: 'api/v1/Opportunity/Opportunity/%s',
      getOpportunityList: 'api/v1/Opportunity/Opportunities/%s',
      getOpportunityType: 'api/v1/Opportunity/OpportunityTypes',
      getScopingOutcome: 'api/V1/ScopingPlan/Outcome/%s',
      getOverviewActiveCount: 'api/v1/Workshop/%s/OverviewActiveCount',
      getOverviewHighAttendanceCount: 'api/v1/Workshop/%s/OverviewHighAttendanceCount',
      getOverviewLowAttendanceCount: 'api/v1/Workshop/%s/OverviewLowAttendanceCount',
      getOverviewUpcomingCount: 'api/v1/Workshop/%s/OverviewUpcomingCount',
      getAllPlannedItems: 'api/v1/ScopingPlan/PlannedItems/%s',
      getRaveCaseDetails: 'api/v1/Case/CaseDetails/Rave/%s',
      getReporteeAssignments: 'api/v1/Landing/Assignments/%s?status=%s&landingPageType=%s',
      getReporteeRequests: 'api/v1/Landing/Requests/%s?status=%s&landingPageType=%s',
      getRequestCancelReasonCode: 'api/v1/Maintenance/Request/CancelReasonCode',
      getRequestDetailsById: 'api/v1/Requests/%s/Details',
      getRequests: 'api/v1/Landing/Requests?requestStatus=%s&landingPageType=%s',
      getRequestsByTypeAndStatus: 'api/v1/Landing/Requests/%s',
      getResourceDetailsById: 'api/v1/Requests/%s/ResourceDetails',
      getAccountOpportunitiesByCustomerTpids: 'api/v1/Accounts/opportunities',
      getAccountOpportunityByNumber: 'api/v1/Accounts/opportunity/%s',
      getScopingDetailsById: 'api/v1/Scoping/%s',
      getSeatingReports: 'api/v1/SeatingReport',
      getSecurityGroupsByAlias: 'api/v1/Roles/securityGroups',
      getServiceDeskCaseDetails: 'api/v1/Case/CaseDetails/ServiceDesk/%s',
      getShadowResourceDetailsById: 'api/v1/Requests/%s/ShadowResourceDetails',
      getSingleSupportNeeds: 'api/v1/Opportunity/%s',
      getSkillGracePeriod: 'api/v1/Skills/SkillGracePeriod',
      getSkills: 'api/v1/Skills',
      searchDSESTARequests: 'api/v1/Migration/records?alias=%s&account=%s&tpid=%s',
      updateArchiveMigratedRequests: 'api/v1/Migration/ArchiveOrUnarchive',
      getArchiveReasonCode: 'api/v1/Migration/ArchiveReason',
      getStatusReports: 'api/v1/Assignment/StatusReports/%s',
      getServiceCenterSpecialists: 'api/v1/Roles/ServiceCenterSpecialists',
      getSurveyLanguages: 'api/v1/Attendee/SurveyLanguages',
      getSupportProjects: 'api/v1/Migration/SupportProjects/%s',
      getTechnologies: 'api/v1/Opportunity/Technologies',
      getTimezones: 'api/v1/Geo/timezones',
      getTotalWorkshopDocumentCount: 'api/v1/WorkshopDocument/TotalCount',
      getTripReport: 'api/v1/Assignment/%s/%s/TripReport',
      getUserClaims: 'api/v1/Authorization/%s/%s/claims/',
      getWorkshopAttendeeTempalte: 'api/v1/Requests/%s/WorkshopAttendees',
      getWorkshopAttributes: 'api/v1/Workshop/Attributes',
      getWorkshopCoordinators: 'api/v1/Roles/WorkshopCoordinators',
      getWorkshopCounts: 'api/v1/Workshop/%s/WorkshopCounts',
      getWorkshopDates: 'api/v1/Workshop/Dates/%s',
      getWorkshopDetailsById: 'api/v1/Requests/%s/WorkshopDetails',
      getWorkshopLocation: 'api/v1/Workshop/Location/%s',
      getWorkshopLocations: 'api/v1/Workshop/Locations',
      getWorkshopLogistics: 'api/v1/Workshop/%s/Logistics',
      getWorkshopReportAvailableSeats: 'api/v1/WorkshopReport/GetWorkshopReportAvailableSeats',
      getWorkshopReportTechnology: 'api/v1/WorkshopReport/GetWorkshopReportTechnology',
      isAWorkshopCoordinator: 'api/v1/Roles/IsWorkshopCoordinator',
      isServiceCenterSpecialist: 'api/v1/Roles/IsSvCSpecialist',
      mapToSupportProject: 'api/v1/Migration/ScopingDelivery',
      modifyResearchEngineersOfAssignment: 'api/v1/Assignment/ResearchEngineers/%s',
      onCallSearch: 'api/v1/OnCall/GetOnCallSchedule',
      editResourceDates: 'api/v1/Maintenance/ReassignResource/%s',
      reassignWorkshopCoordinator: 'api/v1/Workshop/%s/%s/UpdateCoordinator',
      registerAttendee: 'api/v1/Attendee/%s',
      reopenAssignment: 'api/v1/Maintenance/ReopenAssignment/%s',
      resetBilling: 'api/v1/Requests/%s/ResetBilling',
      retrieveWorkshopReport: 'api/v1/WorkshopReport/RetrieveWorkshopReport',
      retrieveWorkshopReportCount: 'api/v1/WorkshopReport/RetrieveWorkshopReportCount',
      saveStatusReport: 'api/v1/Assignment/SaveStatusReport/%s',
      scheduleForecast: 'api/v1/Forecast/Schedule/%s/%s',
      searchDeliveries: 'api/v1/Search/Deliveries',
      searchDocuments: 'api/v1/WorkshopDocument/SearchDocuments',
      searchOpportunities: 'api/v1/Search/Opportunities',
      searchRequestCompanyNames: 'api/v1/Search/RequestCompanyNames/%s',
      sendConfirmation: 'api/v1/Attendee/Confirmation/%s',
      sendInvitationTemplate: 'api/v1/Attendee/InvitationTemplate',
      sendMeoInvitationTemplate: 'api/v1/Attendee/Invites',
      setAssignmentStatusToOnsite: 'api/v1/Assignment/SetAssignmentStatusToOnsite/%s',
      setAssignmentStatusToReportsPending: 'api/v1/Assignment/SetAssignmentStatusToReportsPending/%s',
      submitCustomRequest: 'api/v1/RequestSubmission/ProactiveCustomServiceRequest',
      submitDesignatedSupportEngineerRequest: 'api/v1/RequestSubmission/DesignatedSupportEngineerRequest',
      submitReactiveRequest: 'api/v1/RequestSubmission/ReactiveServiceRequest',
      submitRequest: 'api/v1/RequestSubmission/ProactiveServiceRequest',
      submitTripReport: 'api/v1/Assignment/TripReport',
      submitWorkshopRequest: 'api/v1/RequestSubmission/ProactiveWorkshopRequest',
      submitDSEDeliveryRequest: 'api/v1/ScopingDSEDeliverySubmission/DSEDelivery',
      submitScopingRequest: 'api/v1/ScopingDSEDeliverySubmission/ScopingDelivery',
      unassignResource: 'api/v1/Maintenance/UnassignResource',
      updateAgreementDetails: 'api/v1/Requests/%s/AgreementDetails',
      updateAttendee: 'api/v1/Attendee/Update/%s',
      updateBillingDetails: 'api/v1/Requests/%s/BillingDetails',
      updateCapacity: 'api/v1/Workshop/UpdateCapacity',
      updateClosedWorkshopAttendee: 'api/v1/Attendee/ClosedWorkshop/UpdateAttendee/%s',
      updateContactsDetails: 'api/v1/Requests/%s/Contacts',
      updateCustomerDetails: 'api/v1/Requests/%s/CustomerDetails',
      updateDraftDelivery: 'api/v1/Draft/%s',
      updateEmailTemplate: 'api/v1/Template/%s',
      updateExpenses: 'api/v1/Assignment/UpdateExpenses/%s',
      updateForecast: 'api/v1/Forecast/%s',
      updateForecastAttendee: 'api/v1/ForecastAttendee/%s',
      updateIssueDetails: 'api/v1/Requests/%s/IssueDetails',
      updateLocation: 'api/v1/Workshop/updateLocation',
      updateOpportunity: 'api/v1/Opportunity/%s',
      updatePlannedItem: 'api/v1/ScopingPlan/PlannedItem/%s',
      updateRequestDetails: 'api/v1/Requests/%s/Details',
      updateScoping: 'api/v1/Scoping/Update/%s/%s/%s',
      updateScopingOutcome: 'api/v1/ScopingPlan/Outcome/%s',
      updateSupportNeed: 'api/v1/Opportunity/%s/supportneed',
      updateWorkshopCapacity: 'api/v1/Workshop/%s/Capacity',
      updateWorkshopDates: 'api/v1/Workshop/%s/UpdateDates',
      updateWorkshopDetails: 'api/v1/Requests/%s/WorkshopDetails',
      updateWorkshopDocument: 'api/v1/WorkshopDocument/Update',
      uploadAttachment: 'api/v1/Attachments/%s',
      uploadAttendeeTemplate: 'api/v1/Requests/%s/UploadAttendees',
      uploadWorkshopDocument: 'api/V1/WorkshopDocument/Upload',
      upsertBillingComment: 'api/v1/BillingComment',
      billingCommentDetailsCommon: 'api/v1/BillingComment/%s'
    },
    oneProfileApi: {
      getCities: 'api/v1/master/citylist?country=%s&state=%s',
      getCountries: 'api/v1/master/country',
      getDirectReportees: 'api/v1/profile/%s/directreportees',
      getProfile: 'api/v2.0/profile/%s',
      getResourceTagsByAlias: 'api/v1/profile/%s/resourcetag',
      getStates: 'api/v2.0/master/%s/states',
      getTimezones: 'api/v1/master/timezone',
      getLanguages: 'api/v1/master/skill/Language Spoken?orgtype=CES',
      getOneProfileContacts: 'api/v1/profile/searchprofile?searchText=%s',
      getSkillHierarchy: 'api/v2.0/master/skillhierarchybyskillid?skillIds=%s&orgtype=CES'
    },
    irisApi: {
      sendTeamsMessage: 'api/irisbot/teams/message'
    },
    images: {
      notFoundImage: 'assets/images/pagenotfound.svg',
      unAuthorizedImage: 'assets/images/unauthorized.svg'
    },
    rmexApi: {
      getFlightedCountries: 'Config/scopedRequest/flightcountries'
    }
  }
};
