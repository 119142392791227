export default class DmApi {
  public static AcceptAssignRequest = 'acceptAssignRequest';
  public static AssignServiceCenterRequest = 'assignServiceCenterRequest';
  public static AddClosedWorkshopAttendee = 'addClosedWorkshopAttendee';
  public static AddUpdateScopingOutcome = 'addUpdateOutcome';
  public static ApproveForecast = 'approveForecast';
  public static AssignForecast = 'assignForecast';
  public static AssignResource = 'assignResource';
  public static BillingCommentDetailsCommon = 'billingCommentDetailsCommon';
  public static CancelAttendee = 'cancelAttendee';
  public static CancelRequest = 'cancelRequest';
  public static CancelResourcelessRequest = 'cancelResourcelessRequest';
  public static ChangeAttendeeStatus = 'changeAttendeeStatus';
  public static CompleteLaborAssist = 'completeLaborAssist';
  public static ConfirmWaitlistAttendee = 'confirmWaitlistAttendee';
  public static CreateCapacity = 'createCapacity';
  public static CreateCritsitRequest = 'CreateCritsitRequest';
  public static CreateDeliveryRequest = 'CreateDeliveryRequest';
  public static CreateDraftDelivery = 'createDraftDelivery';
  public static CreateEmailTemplate = 'createEmailTemplate';
  public static CreateForecast = 'createForecast';
  public static CreateLocation = 'createLocation';
  public static CreateOpportunity = 'createOpportunity';
  public static CreatePlannedItem = 'createPlannedItem';
  public static CreateScoping = 'createScoping';
  public static DeclineForecast = 'declineForecast';
  public static DeleteAttachments = 'deleteAttachment';
  public static DeleteCapacity = 'deleteCapacity';
  public static DeleteClosedWorkshopAttendee = 'deleteClosedWorkshopAttendee';
  public static DeleteEmailTemplate = 'deleteEmailTemplate';
  public static DeleteLocation = 'deleteLocation';
  public static DeleteOpportunity = 'deleteOpportunity';
  public static DeletePlannedItem = 'deletePlannedItem';
  public static DisqualifySupportNeeds = 'disqualifySupportNeeds';
  public static DeleteWorkshopDocument = 'deleteWorkshopDocument';
  public static DownloadWorkshopDocument = 'downloadWorkshopDocument';
  public static DuplicateDelivery = 'duplicateDelivery';
  public static EmailScopingPlan = 'emailScopingPlan';
  public static ExportAllWorkshops = 'exportAllWorkshops';
  public static ExportAssignments = 'exportAssignments';
  public static ExportCapacities = 'exportCapacities';
  public static ExportClosedWorkshopAttendees = 'exportClosedWorkshopAttendees';
  public static ExportForecasts = 'exportForecasts';
  public static ExportInvitedAttendees = 'exportInvitedAttendees';
  public static ExportLocations = 'exportLocations';
  public static ExportManagedAttendees = 'exportManagedAttendees';
  public static ExportManagedAttendeesAuditLog = 'exportManagedAttendeesAuditLog';
  public static ExportMyWorkshops = 'exportMyWorkshops';
  public static ExportPlannedItems = 'exportPlannedItems';
  public static ExportReporteeAssignments = 'exportReporteeAssignments';
  public static ExportReporteeRequests = 'exportReporteeRequests';
  public static ExportRequests = 'exportRequests';
  public static ExportWorkshopDocuments = 'exportWorkshopDocuments';
  public static GetActiveWorkshops = 'getActiveWorkshops';
  public static GetAgreementByAgreementId = 'getAgreementByAgreementId';
  public static GetAgreementDetailsByAgreementId = 'getAgreementDetailsByAgreementId';
  public static GetAgreementDetailsById = 'getAgreementDetailsById';
  public static GetAgreementEligibilityDetails = 'getAgreementEligibilityDetails';
  public static GetAgreementsBySdpId = 'GetAgreementsBySdpId';
  public static GetAgreementsByTamAlias = 'getAgreementsByTamAlias';
  public static GetAgreementType = 'getAgreementType';
  public static GetAirports = 'getAirports';
  public static GetAllClosedWorkshopAttendees = 'getAllAttendees';
  public static GetAllPlannedItems = 'getAllPlannedItems';
  public static GetAllRequestOfferings = 'getAllRequestOfferings';
  public static GetAllRequestProductComponents = 'getAllRequestProductComponents';
  public static GetAllWorkshopCounts = 'getAllWorkshopCounts';
  public static GetAllWorkshopDetails = 'getAllWorkshopDetails';
  public static GetAllWorkshopDetailsWithTop = 'getAllWorkshopDetailsWithTop';
  public static GetAreaGeoInfo = 'getAreaGeoInfo';
  public static GetAssignmentByAssignmentId = 'getAssignmentByAssignmentId';
  public static GetAssignments = 'getAssignments';
  public static GetAttachmentById = 'getAttachmentById';
  public static GetAttachments = 'getAttachments';
  public static GetAttachmentsById = 'getAttachmentsById';
  public static GetAttendeeCapacity = 'getAttendeeCapacity';
  public static GetAuditLog = 'getAuditLogById';
  public static GetBillingDetails = 'getBillingDetailsById';
  public static GetCancellationReasons = 'getCancellationReasons';
  public static GetCapacities = 'getCapacities';
  public static GetCaseDetails = 'getCaseDetailsById';
  public static GetContact = 'getContactsById';
  public static GetNonSupportDraftRequestList = 'getNonSupportDraftRequestList';
  public static GetCustomerDetails = 'getCustomerDetailsById';
  public static GetCustomers = 'getCustomers';
  public static GetDeliverySettings = 'getDeliverySettings';
  public static GetDSEDeliveries = 'getDSEDeliveries';
  public static GetDocumentLanguages = 'getDocumentLanguages';
  public static GetDraftDeliveryById = 'getDraftDeliveryById';
  public static GetDseStaMigrationInformation = 'getMigratedRequestDetails';
  public static GetEmailLanguages = 'getEmailLanguages';
  public static GetEmailTemplate = 'getEmailTemplate';
  public static GetEmailTemplates = 'getEmailTemplates';
  public static GetEmployeeProfile = 'GetEmployeeProfile';
  public static GetEngineerBySkills = 'Skills/GetEngineerBySkills';
  public static GetForecastAttendees = 'getForecastAttendees';
  public static GetForecastCounts = 'getForecastCounts';
  public static GetForecasts = 'getForecasts';
  public static GetGrmAssignmentStatus = 'getGrmAssignmentStatus';
  public static GetInvitedAttendees = 'getInvitedAttendees';
  public static GetIssueDetails = 'getIssueDetailsById';
  public static GetLaborByRequestId = 'getLaborByRequestId';
  public static GetMaintenanceStatus = 'getMaintenanceStatus';
  public static GetManagedAttendees = 'getManagedAttendees';
  public static GetMyWorkshopDetails = 'getMyWorkshopDetails';
  public static GetNonBillableReasons = 'getNonBillableReasons';
  public static GetOffering = 'getOffering';
  public static GetAllServices = 'getAllServices';
  public static GetOfferings = 'getOfferings';
  public static GetOpportunity = 'getOpportunity';
  public static GetOpportunityList = 'getOpportunityList';
  public static GetOpportunityType = 'getOpportunityType';
  public static GetPackageDetailsByAgreementId = 'GetPackageDetailsByAgreementId';
  public static GetRaveCaseDetails = 'getRaveCaseDetails';
  public static GetReporteeAssignments = 'getReporteeAssignments';
  public static GetReporteeRequests = 'getReporteeRequests';
  public static GetRequestCancelReasonCode = 'getRequestCancelReasonCode';
  public static GetRequestDetails = 'getRequestDetailsById';
  public static GetRequests = 'getRequests';
  public static GetRequestsByTypeAndStatus = 'getRequestsByTypeAndStatus';
  public static GetResourceAssignmentDetails = 'getResourceDetailsById';
  public static GetAccountOpportunitiesByCustomerTpids = 'getAccountOpportunitiesByCustomerTpids';
  public static GetAccountOpportunityByNumber = 'getAccountOpportunityByNumber';
  public static GetScopingDetailsById = 'getScopingDetailsById';
  public static GetScopingOutcome = 'getScopingOutcome';
  public static GetSeatingReports = 'getSeatingReports';
  public static GetSecurityGroupsByAlias = 'getSecurityGroupsByAlias';
  public static GetServiceDeskCaseDetails = 'getServiceDeskCaseDetails';
  public static GetShadowResourceAssignmentDetails = 'getShadowResourceDetailsById';
  public static GetSingleSupportNeeds = 'getSingleSupportNeeds';
  public static GetSkillDetails = 'GetSkillDetails';
  public static GetSkillGracePeriod = 'getSkillGracePeriod';
  public static GetSkills = 'getSkills';
  public static SearchDSESTARequests = 'searchDSESTARequests';
  public static GetArchiveReasonCode = 'getArchiveReasonCode';
  public static UpdateArchiveMigratedRequests = 'updateArchiveMigratedRequests';
  public static GetStatusReports = 'getStatusReports';
  public static GetServiceCenterSpecialists = 'getServiceCenterSpecialists';
  public static GetSupportProjects = 'getSupportProjects';
  public static GetSurveyLanguages = 'getSurveyLanguages';
  public static GetTechnologies = 'getTechnologies';
  public static GetTimezones = 'getTimezones';
  public static GetTotalWorkshopDocumentCount = 'getTotalWorkshopDocumentCount';
  public static GetTripReport = 'getTripReport';
  public static GetUserClaims = 'getUserClaims';
  public static GetWorkshopAttendeeTemplate = 'getWorkshopAttendeeTempalte';
  public static GetWorkshopAttributes = 'getWorkshopAttributes';
  public static GetWorkshopCoordinators = 'getWorkshopCoordinators';
  public static GetWorkshopCounts = 'getWorkshopCounts';
  public static GetWorkshopDates = 'getWorkshopDates';
  public static GetWorkshopDetails = 'getWorkshopDetailsById';
  public static GetWorkshopLocation = 'getWorkshopLocation';
  public static GetWorkshopLocations = 'getWorkshopLocations';
  public static GetWorkshopLogistics = 'getWorkshopLogistics';
  public static GetWorkshopOverviewActiveCount = 'getOverviewActiveCount';
  public static GetWorkshopOverviewHighAttendanceCount = 'getOverviewHighAttendanceCount';
  public static GetWorkshopOverviewLowAttendanceCount = 'getOverviewLowAttendanceCount';
  public static GetWorkshopOverviewUpcomingCount = 'getOverviewUpcomingCount';
  public static GetWorkshopReportAvailableSeats = 'getWorkshopReportAvailableSeats';
  public static GetWorkshopReportResult = 'getWorkshopReportResult';
  public static GetWorkshopReportTechnology = 'getWorkshopReportTechnology';
  public static IsAWorkshopCoordinator = 'isAWorkshopCoordinator';
  public static IsServiceCenterSpecialist = 'isServiceCenterSpecialist';
  public static MapToSupportProject = 'mapToSupportProject';
  public static ModifyResearchEngineersOfAssignment = 'modifyResearchEngineersOfAssignment';
  public static OnCallSearch = 'onCallSearch';
  public static EditResourceDates = 'editResourceDates';
  public static ReassignWorkshopCoordinator = 'reassignWorkshopCoordinator';
  public static RegisterAttendee = 'registerAttendee';
  public static ReopenAssignment = 'reopenAssignment';
  public static ResetBilling = 'resetBilling';
  public static RetrieveWorkshopReport = 'retrieveWorkshopReport';
  public static RetrieveWorkshopReportCount = 'retrieveWorkshopReportCount';
  public static SaveAsDraft = 'SaveAsDraft';
  public static SaveStatusReport = 'saveStatusReport';
  public static ScheduleForecast = 'scheduleForecast';
  public static SearchDeliveries = 'searchDeliveries';
  public static SearchDocuments = 'searchDocuments';
  public static SearchOpportunities = 'searchOpportunities';
  public static SearchRequestCompanyNames = 'searchRequestCompanyNames';
  public static SendConfirmation = 'sendConfirmation';
  public static SendInvitationTemplate = 'sendInvitationTemplate';
  public static SendMeoInvitationTemplate = 'sendMeoInvitationTemplate';
  public static ServiceName = 'SupportDelivery';
  public static SetAssignmentStatusToOnsite = 'setAssignmentStatusToOnsite';
  public static SetAssignmentStatusToReportsPending = 'setAssignmentStatusToReportsPending';
  public static SubmitCustomRequest = 'submitCustomRequest';
  public static SubmitDesignatedSupportEngineerRequest = 'submitDesignatedSupportEngineerRequest';
  public static SubmitReactiveRequest = 'submitReactiveRequest';
  public static SubmitRequest = 'submitRequest';
  public static SubmitTripReport = 'submitTripReport';
  public static SubmitWorkshopRequest = 'submitWorkshopRequest';
  public static SubmitDSEDeliveryRequest = 'submitDSEDeliveryRequest';
  public static SubmitScopingRequest = 'submitScopingRequest';
  public static SupportDeliveryApi = 'SupportDelivery.';
  public static TelemetryName = 'Support Delivery';
  public static UnassignResource = 'unassignResource';
  public static UpdateAgreementDetails = 'updateAgreementDetails';
  public static UpdateAttendee = 'updateAttendee';
  public static UpdateBillingDetails = 'updateBillingDetails';
  public static UpdateCapacity = 'updateCapacity';
  public static UpdateClosedWorkshopAttendee = 'updateClosedWorkshopAttendee';
  public static UpdateContactsDetails = 'updateContactsDetails';
  public static UpdateCustomerDetails = 'updateCustomerDetails';
  public static UpdateDraftDelivery = 'updateDraftDelivery';
  public static UpdateEmailTemplate = 'updateEmailTemplate';
  public static UpdateExpenses = 'updateExpenses';
  public static UpdateForecast = 'updateForecast';
  public static UpdateForecastAttendee = 'updateForecastAttendee';
  public static UpdateIssueDetails = 'updateIssueDetails';
  public static UpdateLocation = 'updateLocation';
  public static UpdateOpportunity = 'updateOpportunity';
  public static UpdatePlannedItem = 'updatePlannedItem';
  public static UpdateRequestDetails = 'updateRequestDetails';
  public static UpdateScoping = 'updateScoping';
  public static UpdateScopingOutcome = 'updateScopingOutcome';
  public static UpdateSummary = 'UpdateSummary';
  public static UpdateSupportNeed = 'updateSupportNeed';
  public static UpdateWorkshopCapacity = 'updateWorkshopCapacity';
  public static UpdateWorkshopDates = 'updateWorkshopDates';
  public static UpdateWorkshopDetails = 'updateWorkshopDetails';
  public static UpdateWorkshopDocument = 'updateWorkshopDocument';
  public static UploadAttachment = 'uploadAttachment';
  public static UploadAttendeeTemplate = 'uploadAttendeeTemplate';
  public static UploadWorkshopDocument = 'uploadWorkshopDocument';
  public static UpsertBillingComment = 'upsertBillingComment';
}
