export class UtilitiesHelperService {
  constructor() { }

  public static mergeObjects(destination: object, ...sources: object[]): object {
    const sourceLength = sources.length;
    for (let objIndex = 0; objIndex < sourceLength; objIndex++) {
      const source = sources[objIndex];
      if (this.isObject(source)) {
        Object.keys(source).forEach((key) => {
          const prop = source[key];
          if (this.isObject(prop)) {
            if (!this.isObject(destination[key])) {
              destination[key] = Array.isArray(prop) ? [] : {};
            }
            this.mergeObjects(destination[key], prop);

          } else {
            destination[key] = prop;
          }
        });
      }
    }
    return destination;
  }

  private static isObject(obj: any): boolean {
    return (!!obj) && (obj.constructor === Object);
  }
}
