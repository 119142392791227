import {
  IAppService,
  IStateConfig,
  IFxpAppContext,
  IServiceEndPoints,
  IRouteInfo,
  IPartnerBundle,
  IAppServiceWithSharedComponent,
  ISharedComponentConfig,
  ISharedComponents
} from '@fxp/fxpservices';
import { appSettings } from './environments/common/appsettingsGenerator';
import DmApi from './app/constants/dmapi.constants';
import RoutesConstant from './app/constants/routes-constant';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class DmApp_routes implements IAppService, IAppServiceWithSharedComponent {
  getRoutes(fxpContext: IFxpAppContext): IRouteInfo {
    const stateCollection: Array<IStateConfig> = [
      {
        name: RoutesConstant.RequestDetailsRouteName,
        url: RoutesConstant.RequestDetailsRouteUrl,

generatedBundle: '22b2c47e31ae-RequestModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Requests',
          headerName: 'Request Details',
          pageNameForTelemetry: 'Request Details',
          pageTitle: 'Support Delivery',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.MyDeliveriesRouteName,
        url: RoutesConstant.MyDeliveriesRouteUrl,

generatedBundle: '22b2c47e31ae-MyDeliveriesModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Deliveries',
          headerName: 'My Deliveries',
          pageNameForTelemetry: 'MyDeliveries',
          pageTitle: 'Support Delivery',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.RequestNotFoundName,
        url: RoutesConstant.RequestNotFoundUrl,

generatedBundle: '22b2c47e31ae-NotFoundModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Not found',
          headerName: 'Request not found',
          pageNameForTelemetry: 'NotFound',
          pageTitle: 'Request not found',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.RequestUnauthorizedName,
        url: RoutesConstant.RequestUnauthorizedUrl,

generatedBundle: '22b2c47e31ae-UnauthorizedModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Unauthorized',
          headerName: 'Request unauthorized',
          pageNameForTelemetry: 'Unauthorized',
          pageTitle: 'Request unauthorized',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.WorkshopManageAttendeesRouteName,
        url: RoutesConstant.WorkshopManageAttendeesUrl,

generatedBundle: '22b2c47e31ae-WorkshopsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Manage Attendees',
          headerName: 'Workshop Manage Attendees',
          pageNameForTelemetry: 'ManageAttendees',
          pageTitle: 'Manage Attendees',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.SubmissionRouteName,
        url: RoutesConstant.SubmissionRouteUrl,

generatedBundle: '22b2c47e31ae-SubmissionModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Submit',
          headerName: 'Submission',
          pageNameForTelemetry: 'SupportDelivery.RequestSubmission',
          pageTitle: 'Support Delivery',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.WorkshopRouteName,
        url: RoutesConstant.WorkshopsRouteUrl,

generatedBundle: '22b2c47e31ae-WorkshopsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Workshop Management',
          headerName: 'Workshop Management',
          pageNameForTelemetry: 'WorkshopManagement',
          pageTitle: 'Support Delivery',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.AdvancedSearchRouteName,
        url: RoutesConstant.AdvancedSearchRouteUrl,

generatedBundle: '22b2c47e31ae-AdvancedSearchModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Search',
          headerName: 'Search',
          pageNameForTelemetry: 'Search',
          pageTitle: 'Search',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.SupportNeedsRouteName,
        url: RoutesConstant.SupportNeedsRouteUrl,

generatedBundle: '22b2c47e31ae-SupportNeedsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Support Needs',
          headerName: 'Support Needs',
          pageNameForTelemetry: 'Support Needs',
          pageTitle: 'Support Needs',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.DSESTARequestMigrationRouteName,
        url: RoutesConstant.DSESTARequestMigrationRouteUrl,

generatedBundle: '22b2c47e31ae-DSESTARequestsMigrationModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'DSE/STA Request Migration',
          headerName: 'DSE/STA Request Migration',
          pageNameForTelemetry: 'DSE/STA Request Migration',
          pageTitle: 'DSE/STA Request Migration',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.EmailTemplatesRouteName,
        url: RoutesConstant.EmailTemplatesRouteUrl,

generatedBundle: '22b2c47e31ae-SettingsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Email Templates',
          headerName: 'Email Templates',
          pageNameForTelemetry: 'Manage Templates',
          pageTitle: 'Manage Templates',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.settingsRouteName,
        url: RoutesConstant.settingsRouteUrl,

generatedBundle: '22b2c47e31ae-SettingsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Settings',
          headerName: 'Settings',
          pageNameForTelemetry: 'Settings',
          pageTitle: 'Settings',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.ReportsRouteName,
        url: RoutesConstant.ReportsRouteUrl,

generatedBundle: '22b2c47e31ae-ReportsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Reports',
          headerName: 'Reports',
          pageNameForTelemetry: 'Reports',
          pageTitle: 'Reports',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.SeatingReportRouteName,
        url: RoutesConstant.SeatingReportRouteUrl,

generatedBundle: '22b2c47e31ae-ReportsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Seating Report',
          headerName: 'Seating Report',
          pageNameForTelemetry: 'Seating Report',
          pageTitle: 'Seating Report',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.WorkshopReportRouteName,
        url: RoutesConstant.WorkshopReportRouteUrl,

generatedBundle: '22b2c47e31ae-ReportsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Workshop Report',
          headerName: 'Workshop Report',
          pageNameForTelemetry: 'Workshop Report',
          pageTitle: 'Support Delivery',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.SupportNeedDetailsRouteName,
        url: RoutesConstant.SupportNeedDetailsRouteUrl,

generatedBundle: '22b2c47e31ae-SupportNeedsModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'Support Needs Details',
          headerName: 'Support Needs',
          pageNameForTelemetry: 'Support Needs',
          pageTitle: 'Support Needs',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.OnCallSearchRouteName,
        url: RoutesConstant.OnCallSearchRouteUrl,

generatedBundle: '22b2c47e31ae-OnCallSearchModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'OnCallSearch',
          headerName: 'On Call Search',
          pageNameForTelemetry: 'On Call Search',
          pageTitle: 'On Call Search',
          partnerTelemetryName: DmApi.ServiceName
        }
      },
      {
        name: RoutesConstant.SharedComponentSandboxRouteName,
        url: RoutesConstant.SharedComponentSandboxRouteUrl,

generatedBundle: '22b2c47e31ae-SharedComponentSandboxModule-Bundle',
        data: {
          appNameForTelemetry: DmApi.TelemetryName,
          breadcrumbText: 'SharedComponentSandbox',
          headerName: 'Shared Component Sandbox',
          pageNameForTelemetry: 'Shared Component Sandbox',
          pageTitle: 'Shared Component Sandbox',
          partnerTelemetryName: DmApi.ServiceName
        }
      }
    ];
    const routeInfo: IRouteInfo = {
      applicationName: 'DM',
      sharedBundles: [],
      routes: stateCollection
    };
    return routeInfo;
  }

  getServiceEndPoints(): Array<IServiceEndPoints> {
    return appSettings().serviceEndPoints;
  }

  getBundles(): IPartnerBundle[] {
    const baseUrl = appSettings().cdnBaseUrl;
    const bundle: Array<IPartnerBundle> = [
      {
        name: 'DM-Bundle',
        files: [`${baseUrl}/vendor.bundle.js`, `${baseUrl}/styles.bundle.js`, `${baseUrl}/main.bundle.js`],
        sequentialLoading: true
      }
    ];
    return bundle;
  }

  getSharedComponentsInfo(): ISharedComponents {
    const submissionSharedComponent: ISharedComponentConfig = {

generatedBundle: '22b2c47e31ae-SubmissionModule-Bundle',
      componentDescription: 'This component renders and handles the submission form for support delivery',
      componentInputs: [
        {
          variableName: 'shouldSubmitDelivery',
          variableDescription: 'Function which is called with the current form state and returns true or false'
        },
        {
          variableName: 'enabledDeliveryTypeList',
          variableDescription: 'List of request types to enable in the form'
        },
        {
          variableName: 'enableCreateDraft',
          variableDescription: 'Whether the submission is a create or update'
        },
        {
          variableName: 'presetDraftID',
          variableDescription: 'Value of preselected draft id'
        },
        {
          variableName: 'preselectedRequestType',
          variableDescription: 'Value for request type to be selected when first loading the form'
        },
        {
          variableName: 'disableTypeOfRequestSelection',
          variableDescription: 'Disable type of request selection'
        },
        {
          variableName: 'listOfPackages',
          variableDescription:
            'List of predefined packages, including csamAlias, agreementID, packageId and packageName'
        },
        {
          variableName: 'title',
          variableDescription: 'String with the title to be displayed in the top left'
        },
        {
          variableName: 'partnerApplication',
          variableDescription: 'Partner application name provided by Support Delivery team'
        },
        {
          variableName: 'requestIDToDuplicate',
          variableDescription: 'Request id of delivery to be duplicated'
        },
        {
          variableName: 'disableNextButton',
          variableDescription: 'Disable next button in submission form'
        },
        {
          variableName: 'disableNextButtonInfoMessage',
          variableDescription: 'Message to be displayed when hovering over the disabled next button'
        },
        {
          variableName: 'customerTpids',
          variableDescription: 'List of TPIDs for the Customer the delivery is being created for'
        },
        {
          variableName: 'isProspectiveCustomer',
          variableDescription: 'Indicator if the customer is a prospective or existing'
        }
      ],
      componentOutputs: [
        {
          variableName: 'emitSaveDelivery',
          variableDescription:
            'Observable that completes with the tracking number of the request created, or fails and returns trackingId'
        },
        {
          variableName: 'emitCreateUpdateDraftDelivery',
          variableDescription:
            'Observable that completes with the request id of the request created, or fails and returns trackingId'
        }
      ],
      componentName: 'submitDelivery'
    };

    const simplifiedCreateDeliverySharedComponent: ISharedComponentConfig = {

generatedBundle: '22b2c47e31ae-SubmissionModule-Bundle',
      componentDescription: 'This component renders and handles the simplified submission form for support delivery',
      componentInputs: [
        {
          variableName: 'shouldSubmitDelivery',
          variableDescription: 'Function which is called with the current form state and returns true or false'
        },
        {
          variableName: 'enabledDeliveryTypeList',
          variableDescription: 'List of request types to enable in the form'
        },
        {
          variableName: 'enableCreateDraft',
          variableDescription: 'Whether the submission is a create or update'
        },
        {
          variableName: 'presetDraftID',
          variableDescription: 'Value of preselected draft id'
        },
        {
          variableName: 'preselectedRequestType',
          variableDescription: 'Value for request type to be selected when first loading the form'
        },
        {
          variableName: 'disableTypeOfRequestSelection',
          variableDescription: 'Disable type of request selection'
        },
        {
          variableName: 'listOfPackages',
          variableDescription:
            'List of predefined packages, including csamAlias, agreementID, packageId and packageName'
        },
        {
          variableName: 'title',
          variableDescription: 'String with the title to be displayed in the top left'
        },
        {
          variableName: 'partnerApplication',
          variableDescription: 'Partner application name provided by Support Delivery team'
        },
        {
          variableName: 'requestIDToDuplicate',
          variableDescription: 'Request id of delivery to be duplicated'
        },
        {
          variableName: 'disableNextButton',
          variableDescription: 'Disable next button in submission form'
        },
        {
          variableName: 'disableNextButtonInfoMessage',
          variableDescription: 'Message to be displayed when hovering over the disabled next button'
        },
        {
          variableName: 'customerTpids',
          variableDescription: 'List of TPIDs for the Customer the delivery is being created for'
        },
        {
          variableName: 'isProspectiveCustomer',
          variableDescription: 'Indicator if the customer is a prospective or existing'
        },
        {
          variableName: 'disableSubmit',
          variableDescription: 'Whether the submission should be restricted to draft only'
        },
        {
          variableName: 'enableCancelForm',
          variableDescription: 'Whether the form needs a cancel button for a modal or flyout component'
        },
        {
          variableName: 'deliverySettingsId',
          variableDescription: 'Delivery Settings id to use to prepopulate delivery settings section'
        },
        {
          variableName: 'showTypeOfRequestField',
          variableDescription: 'Whether to display the drop down for Type of Request selection'
        },
        {
          variableName: 'reduceContainerHeightBy',
          variableDescription: 'Reduce default height of the container by x pixels'
        },
        {
          variableName: 'isFederalUser',
          variableDescription: 'Indicator if the user is Federal'
        }
      ],
      componentOutputs: [
        {
          variableName: 'emitSaveDelivery',
          variableDescription:
            'Observable that completes with the tracking number of the request created, or fails and returns trackingId'
        },
        {
          variableName: 'emitCreateUpdateDraftDelivery',
          variableDescription:
            'Observable that completes with the request id of the request created, or fails and returns trackingId'
        },
        {
          variableName: 'emitCancelSubmissionForm',
          variableDescription: 'Observable that notifies modal or flyout of cancel action'
        }
      ],
      componentName: 'dm-simplifiedcreatedelivery'
    };

    const nonSupportDraftRequestListComponent: ISharedComponentConfig = {

generatedBundle: '22b2c47e31ae-NonSupportRequestModule-Bundle',
      componentDescription: 'This component renders and handles the CSA Manager draft List',
      componentName: 'dm.nonSupportDraftList',
      componentInputs: [
        {
          variableName: 'filterCriteria',
          variableDescription: 'filter categories with corresponding values'
        }
      ]
    };

    return {
      appName: 'DM',
      components: [
        submissionSharedComponent,
        simplifiedCreateDeliverySharedComponent,
        nonSupportDraftRequestListComponent
      ],
      disableSharing: false,
      sharedBundles: []
    };
  }
  public getGeneratedBundles() {
    var baseUrl = '';
    var currentScriptUrl = document.currentScript['src'];
    if (currentScriptUrl.indexOf('exclude.inline.bundle') > 0) {
      currentScriptUrl = Error().stack.match(/(https?:\/\/[^ ]*)/g);
      baseUrl = currentScriptUrl
        .filter((item) => item.indexOf('localhost:5000') === -1)[0]
        .toLowerCase();
      baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    } else {
      baseUrl = currentScriptUrl.substring(0, currentScriptUrl.lastIndexOf('/'));
    }
    if (baseUrl.endsWith("/")){
        baseUrl = baseUrl.substring(0, baseUrl.lastIndexOf('/'));
    }
    // Support Delivery specific: If the URL has build parameter, then load the bundles from the build folder
    var sdBuildId = new URLSearchParams(window.location.hash.split("?").pop()).get("build");
    if (sdBuildId) {
      baseUrl = baseUrl.replace("/angular","/build") + "/" + sdBuildId + "/angular";
    }
    return [
            {
                name: '22b2c47e31ae-RequestModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/RequestModule.vendor.bundle.js',
                    baseUrl + '/RequestModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-MyDeliveriesModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/MyDeliveriesModule.vendor.bundle.js',
                    baseUrl + '/MyDeliveriesModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-NotFoundModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/NotFoundModule.vendor.bundle.js',
                    baseUrl + '/NotFoundModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-UnauthorizedModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/UnauthorizedModule.vendor.bundle.js',
                    baseUrl + '/UnauthorizedModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-WorkshopsModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/WorkshopsModule.vendor.bundle.js',
                    baseUrl + '/WorkshopsModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-SubmissionModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/SubmissionModule.vendor.bundle.js',
                    baseUrl + '/SubmissionModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-AdvancedSearchModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/AdvancedSearchModule.vendor.bundle.js',
                    baseUrl + '/AdvancedSearchModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-SupportNeedsModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/SupportNeedsModule.vendor.bundle.js',
                    baseUrl + '/SupportNeedsModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-DSESTARequestsMigrationModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/DSESTARequestsMigrationModule.vendor.bundle.js',
                    baseUrl + '/DSESTARequestsMigrationModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-SettingsModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/SettingsModule.vendor.bundle.js',
                    baseUrl + '/SettingsModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-ReportsModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/ReportsModule.vendor.bundle.js',
                    baseUrl + '/ReportsModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-OnCallSearchModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/OnCallSearchModule.vendor.bundle.js',
                    baseUrl + '/OnCallSearchModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-SharedComponentSandboxModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/SharedComponentSandboxModule.vendor.bundle.js',
                    baseUrl + '/SharedComponentSandboxModule.bundle.js'
                ],
                sequentialLoading: true           
            },
    {
                name: '22b2c47e31ae-NonSupportRequestModule-Bundle',
                files: [
                    baseUrl + '/styles.bundle.js',
                    baseUrl + '/NonSupportRequestModule.vendor.bundle.js',
                    baseUrl + '/NonSupportRequestModule.bundle.js'
                ],
                sequentialLoading: true           
            },

    ]
}
}

PartnerAppRegistrationService.registerLazyLoadedApp(DmApp_routes, 'DM');
